import ComingSoonPage from "./ComingSoon";
import Test from "./TestPage";


import { useState } from "react"

export default function Landing() {


    return (
        <div >
            <h2>Landing Page</h2>
            < ComingSoonPage />
        </div>
    );
  }
