import ComingSoonPage from "./ComingSoon";



export default function Dump() {


    return (
        <div >
            <h2>Brain Dump</h2>
            <ComingSoonPage />
        </div>
    );
  }
