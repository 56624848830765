import '../../Styling/errorPage.css'

export default function ComingSoonPage() {

  return (
    <div id="error-page">
      <img src="/beePainting.png" alt="Bee wearing small hat and painting tiny house" />
      <h1>Page Coming Soon!</h1>
      <p>Please be patient, he's doing his best.</p>

    </div>
  );
}
